export const languages = [
  { label: 'English', value: 'en' },
  { label: 'Italian', value: 'it' },
  { label: 'French', value: 'fr' },
  { label: 'Chinese', value: 'zh' },
  { label: 'Spanish', value: 'es' },
  { label: 'German', value: 'de' },
  // { label: t('Russia'), value: 'ru' },
  { label: 'Portuguese', value: 'pt' },
  // { label: t('Polish'), value: 'pl' },
  // { label: t('Greek'), value: 'el' },
  // { label: t('Bulgarian'), value: 'bg' },
  // { label: t('Turkish'), value: 'tr' },
  // { label: t('Romanian'), value: 'ro' },
  // { label: t('Hungarian'), value: 'hu' },
  // { label: t('Chinese'), value: 'zh' },
];
